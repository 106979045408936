// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-activity-js": () => import("/opt/build/repo/src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-team-js": () => import("/opt/build/repo/src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-testimonial-js": () => import("/opt/build/repo/src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("/opt/build/repo/src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-activities-index-js": () => import("/opt/build/repo/src/pages/activities/index.js" /* webpackChunkName: "component---src-pages-activities-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-index-js": () => import("/opt/build/repo/src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-tips-index-js": () => import("/opt/build/repo/src/pages/tips/index.js" /* webpackChunkName: "component---src-pages-tips-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

